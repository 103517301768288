import { Inject, Injectable, ModuleWithProviders, NgModule, Optional, PLATFORM_ID } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TransferHttpCacheModule } from '@nguniversal/common'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { DeviceDetectorService } from 'ngx-device-detector'
import { DeferLoadModule } from '@trademe/ng-defer-load'
import { CommonModule, isPlatformServer } from '@angular/common'
import { ImageComponent } from './components/sharedComponent/image.component'
import { BlogDesktopComponent } from './components/sharedComponent/blog-component/blog-desktop/blog-desktop.component'
import { AppearModule } from '@flowaccount/landing'
import { FaqDesktopComponent } from './components/sharedComponent/faq-component/faq-desktop/faq-desktop.component'
import { DownloadpdfDesktopComponent } from './components/sharedComponent/downloadpdf-component/downloadpdf-desktop/downloadpdf-desktop.component'
import { CtaDesktopComponent } from './components/sharedComponent/cta-component/cta-desktop/cta-desktop.component'
import { TechBlogComponent } from './components/sharedComponent/tech-blog/tech-blog.component'
import { FaqAccordionComponent } from './components/sharedComponent/faq-accordion/faq-accordion.component'
import { BlogSwiperComponent } from './components/sharedComponent/blog-swiper/blog-swiper.component'
import { ConnectionAddOnPackageComponent } from './components/sharedComponent/connection-add-on-package/connection-add-on-package.component'
import { InviteToUseSectionComponent } from './components/sharedComponent/invite-to-use-section/invite-to-use-section.component'
import { TaxCalendarSwiperComponent } from './components/sharedComponent/tax-calendar-swiper/tax-calendar-swiper.component'
import { ReviewSetionComponent } from './components/sharedComponent/review-setion/review-setion.component'
import { BlogSwiperSquidexComponent } from './components/sharedComponent/blog-swiper-squidex/blog-swiper-squidex.component'
import { FaqAccordionSquidexComponent } from './components/sharedComponent/faq-accordion-squidex/faq-accordion-squidex.component'
import { PromotionalBannerSquidexComponent } from './components/sharedComponent/promotional-banner-squidex/promotional-banner-squidex.component'
import { StarterKitSharedComponent } from './components/sharedComponent/starter-kit-shared/starter-kit-shared.component'
import { ReactiveFormsModule } from '@angular/forms'
import { ContentCallToActionSquidexComponent } from './components/sharedComponent/content-call-to-action-squidex/content-call-to-action-squidex.component'
import { TranslateModule } from '@ngx-translate/core'
import { ContentCallToActionPrivilegeComponent } from './components/sharedComponent/content-call-to-action-privilege/content-call-to-action-privilege.component'
import { REQUEST } from '../express.tokens'
import { register as swiperElement } from 'swiper/element/bundle'
swiperElement()
import { SwiperDirective } from './directive/swiper.directive'
import { TaxInvoiceBannerSharedComponent } from './components/sharedComponent/tax-invoice-banner-shared/tax-invoice-banner-shared.component'
import { SeminarTrainingBannerSharedComponent } from './components/sharedComponent/seminar-training-banner-shared/seminar-training-banner-shared.component'
import { SeminarReviewsTestimonialComponent } from './components/seminar/seminar/seminar-reviews-testimonial/seminar-reviews-testimonial.component'
import { ContactSupportComponent } from './components/sharedComponent/contact-support/contact-support.component'
import { MobileDocumentsComponent } from './components/sharedComponent/mobile-documents/mobile-documents.component'
import { PolicyServiceSharedComponent } from './components/sharedComponent/policy-service-shared/policy-service-shared.component'
import { RouterModule } from '@angular/router'
import { MemberEachRegionComponent } from './components/accountingfirm/become-our-partner/member-each-region/member-each-region.component'
import { SkeletonLoaderModule } from './components/sharedComponent/skeleton-loader/skeleton-loader.module'
import { SmoothScrollAnchorSharedComponent } from './components/sharedComponent/smooth-scroll-anchor-shared/smooth-scroll-anchor-shared.component'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
@Injectable()
export class UniversalDeviceDetectorService extends DeviceDetectorService {
  constructor(@Inject(PLATFORM_ID) platformId: any, @Optional() @Inject(REQUEST) request: any) {
    super(platformId)
    if (isPlatformServer(platformId)) {
      super.setDeviceInfo((request.headers['user-agent'] as string) || '')
    }
  }
}
@NgModule({
  declarations: [
    ImageComponent,
    BlogDesktopComponent,
    FaqDesktopComponent,
    DownloadpdfDesktopComponent,
    CtaDesktopComponent,
    TechBlogComponent,
    FaqAccordionComponent,
    BlogSwiperComponent,
    ConnectionAddOnPackageComponent,
    InviteToUseSectionComponent,
    TaxCalendarSwiperComponent,
    ReviewSetionComponent,
    BlogSwiperSquidexComponent,
    FaqAccordionSquidexComponent,
    PromotionalBannerSquidexComponent,
    StarterKitSharedComponent,
    ContentCallToActionSquidexComponent,
    ContentCallToActionPrivilegeComponent,
    SwiperDirective,
    TaxInvoiceBannerSharedComponent,
    SeminarTrainingBannerSharedComponent,
    SeminarReviewsTestimonialComponent,
    ContactSupportComponent,
    MobileDocumentsComponent,
    PolicyServiceSharedComponent,
    MemberEachRegionComponent,
    SmoothScrollAnchorSharedComponent,
  ],
  imports: [
    CommonModule,
    AppearModule,
    LazyLoadImageModule,
    TransferHttpCacheModule,
    DeferLoadModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SkeletonLoaderModule,
  ],
  exports: [
    DeferLoadModule,
    TransferHttpCacheModule,
    LazyLoadImageModule,
    ImageComponent,
    BlogDesktopComponent,
    FaqDesktopComponent,
    DownloadpdfDesktopComponent,
    CtaDesktopComponent,
    TechBlogComponent,
    FaqAccordionComponent,
    BlogSwiperComponent,
    ConnectionAddOnPackageComponent,
    InviteToUseSectionComponent,
    TaxCalendarSwiperComponent,
    ReviewSetionComponent,
    BlogSwiperSquidexComponent,
    FaqAccordionSquidexComponent,
    PromotionalBannerSquidexComponent,
    StarterKitSharedComponent,
    ContentCallToActionSquidexComponent,
    ContentCallToActionPrivilegeComponent,
    SwiperDirective,
    TaxInvoiceBannerSharedComponent,
    SeminarTrainingBannerSharedComponent,
    SeminarReviewsTestimonialComponent,
    ContactSupportComponent,
    MobileDocumentsComponent,
    PolicyServiceSharedComponent,
    MemberEachRegionComponent,
    SkeletonLoaderModule,
    SmoothScrollAnchorSharedComponent,
  ],
  providers: [
    {
      provide: DeviceDetectorService,
      useClass: UniversalDeviceDetectorService,
    },
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    }
  }
}
export interface IErrorResponse {
  errorMessage: string
}
